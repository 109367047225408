
//user
import VouchersHome from '../pages/vouchers/vouchers-home.vue';
import SellVoucher from '../pages/vouchers/sell-voucher.vue';
import RedeemVoucher from '../pages/vouchers/redeem-voucher.vue';
import VoucherRecipient from '../pages/vouchers/voucher-recipient.vue';
import VoucherHistory from '../pages/vouchers/voucher-history.vue';
import LoginPage from '../pages/user/login-page.vue';
import ResetPassword from '../pages/user/reset-password.vue';
import RegisterPage from '../pages/user/register-page.vue';
import UserSettings from '../pages/user/settings-page.vue';
import UserUsers from '../pages/user/users-page.vue';
import UserUser from '../pages/user/user-page.vue';
import SupportMessage from '../pages/support/support-message.vue';
// admin
import VoucherBatches from '../pages/admin/vouchers/batches.vue';
import CreateVoucherBatch from '../pages/admin/vouchers/create-batch.vue';
import AccountsPage from '../pages/admin/accounts/accounts.vue';
import NotFoundPage from '../pages/404.vue';



var routes = [
	{
    path: '/',
    async: function ({ router, to, resolve }) {
      var app = this.app;
      app.preloader.show();

			if(localStorage.access_token){
				// set up token in header globally
				app.request.setup({headers: {'Authorization':'Bearer '+localStorage.access_token, 'Accept':'application/json'} })

				app.request.get( app.store.state.api_url+'user/this-user').then((response) => {
					app.preloader.hide();
					response = JSON.parse(response.data);
					if(response.status == 1){
						//app.store.state.user = response.data.user;
						app.store.dispatch('addUser', response.data.user )
						app.store.state.account = response.data.account;

						// decide which page to display based on user role
						if(response.data.user.role_id == 4){
							resolve({component: VoucherRecipient});
						}else{
							resolve({component: VouchersHome});
						}

					}else{
						resolve({component: LoginPage});
					}
				}).catch(function (err) {
			    console.log(err.xhr)
			    console.log(err.status)
			    console.log(err.message)
					app.preloader.hide();
					localStorage.access_token = ''
					app.dialog.alert(JSON.parse(err.xhr.response).message);
					resolve({component: LoginPage});
			  });

			}else{
				app.preloader.hide();
				resolve({component: LoginPage});
			}

    },

  },
	{
		path: '/vouchers-home/',
		component: VouchersHome,
	},
	{
		path: '/sell-voucher/',
		component: SellVoucher,
	},
	{
		path: '/redeem-voucher/',
		component: RedeemVoucher,
	},
	{
		path: '/voucher-recipient/',
		component: VoucherRecipient,
	},
	{
		path: '/voucher-history/',
		component: VoucherHistory,
	},

  {
    path: '/user/login/',
    component: LoginPage,
  },

	{
		path: '/user/reset-password/',
		component: ResetPassword,
	},

	{
    path: '/user/register/',
    component: RegisterPage,
  },

  {
    path: '/user-settings/',
    component: UserSettings,
  },

	{
    path: '/user-users/',
    component: UserUsers,
  },

	{
    path: '/user-user/',
    component: UserUser,
		options: {
			props: {
				user: Object,
				},
		},
  },

  {
    path: '/admin/vouchers/batches/',
    component: VoucherBatches,
  },

	{
		path: '/admin/vouchers/create-batch/',
		component: CreateVoucherBatch,
	},

	{
		path: '/admin/accounts/',
		component: AccountsPage,
	},


	{
		path: '/support/support-message/',
		component: SupportMessage,
	},

	{
		path: '(.*)',
		component: NotFoundPage,
	},



];

export default routes;
