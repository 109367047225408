<template>
  <f7-page no-navbar name="redeem">


		<!-- Views/Tabs container -->
		<f7-views tabs class="safe-areas">

			<f7-toolbar tabbar labels bottom>
				<f7-link tab-link="#sell-voucher-tab" tab-link-active icon-ios="f7:tray_arrow_up" icon-aurora="f7:tray_arrow_up" icon-md="f7:tray_arrow_up" text="Sell Voucher"></f7-link>
				<f7-link tab-link="#redeem-voucher-tab" icon-ios="f7:tray_arrow_down" icon-aurora="f7:tray_arrow_down" icon-md="f7:tray_arrow_down" text="Redeem Voucher"></f7-link>
			</f7-toolbar>

			<f7-view id="sell-voucher-tab" name="sell" sell tab tab-active url="/sell-voucher/"></f7-view>
			<f7-view id="redeem-voucher-tab" name="redeem" redeem  tab url="/redeem-voucher/"></f7-view>

		</f7-views>



		</f7-page>
		</template>


		<script>

		import { ApiMixin } from '../../mixins/api-mixin.js'
		var moment = require('moment')

		export default {

			mixins: [ApiMixin],

			data() {
			  return {
			    voucherCode: '',
					saleDate: [new Date()],
			  };
			},

			mounted() {

			},


			methods: {

		    setInputValid(isValid){
					if(isValid == 1.1){return true;}else{return false;}
				}

			},


		};
		</script>
