<template>
  <f7-page name="Voucher Batch">
    <f7-navbar >
			<f7-nav-left>
				<f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
			</f7-nav-left>
			<f7-nav-title sliding>Create Voucher Batch</f7-nav-title>
			<f7-nav-right>
			 <img style="height:45px; margin-right:5px;" src="../../../assets/images/pif-logo.png" >
			</f7-nav-right>
		</f7-navbar>


		<f7-list no-hairlines-md inset>

			<f7-list-input
				floating-label
				outline
				label="Batch Name"
				type="text"
				placeholder="Batch Name"
				v-model:value="batch_name"
				required
			></f7-list-input>

			<f7-list-input
				floating-label
				outline
				label="Number of vouchers to create"
				placeholder="Number"
				v-model:value="batch_size"
				type="text"
				required
			></f7-list-input>

			<f7-list-input
				floating-label
				outline
				label="Voucher Value £"
				placeholder="Number"
				v-model:value="voucher_value"
				type="text"
				required
			></f7-list-input>

			<f7-list-input
				floating-label
				outline
				label="Notes"
				placeholder="Notes"
				v-model:value="batch_notes"
				type="textarea"
			></f7-list-input>

			<br><br>

			<f7-button class="col"  @click="createBatch()" color="yellow"  large fill round raised>Generate Codes</f7-button>
			<br><br>

			<f7-button class="col" v-if="showDownloadButton" @click="downloadBatch()" color="blue"  large fill round raised>Download Codes</f7-button>

		</f7-list>


		<br><br><br>


</f7-page>
</template>


		<script>

		import { ApiMixin } from '../../../mixins/api-mixin.js'
		import { f7 } from 'framework7-vue';
		var moment = require('moment')

		export default {

			mixins: [ApiMixin],

			data() {
			  return {
			    batch_name: '',
					batch_size: 20,
					voucher_value: 5.00,
					batch_notes: '',
					showDownloadButton:false,
					voucher_batch_id:0,
			  };
			},

			mounted() {

			},


			methods: {

				validate(){
					if(this.batch_name.length < 1){f7.dialog.alert('You must enter a batch name (this can be anything you like, to help remember what the batch was for).'); return false;}
					if(!(this.batch_size > 0)){f7.dialog.alert('You must enter a value for batch size ( the number of codes to be created).'); return false;}
					if(!(this.voucher_value > 0)){f7.dialog.alert('You must enter an amount for voucher value ( what value is the voucher, for eg £5).'); return false;}
					return true;
				},

		    createBatch(isValid){
					if(!this.validate()){ return ;}
					
					var self = this
					f7.preloader.show();
					this.ApiCreateVoucherBatch(this.batch_name, this.batch_size, this.voucher_value, this.batch_notes).then(function(response){
						if(response.status == 1){
							f7.preloader.hide();
							f7.dialog.alert('Your batch of voucher codes was created');
							self.showDownloadButton = true;
							self.voucher_batch_id = response.data.id
						}
					});
				},

				downloadBatch(isValid){
					var self = this
					f7.preloader.show();
					this.ApiDownloadVoucherBatch(self.voucher_batch_id).then(function(success){
						if(success){
							f7.preloader.hide();
							f7.dialog.alert('Check your email. A csv file with the voucher codes has been sent to you.');
						}
					});
				}




			},


		};
		</script>
