
import { createStore } from 'framework7/lite';


const store = createStore({


  state: {
		api_url: 'https://api.pifgiftvouchers.org/api/',
		//api_url: 'http://api.pifgiftvouchers.local.org/api/',
		isAdminUser:false,
		userRoleId:100,
		isAccountOwner:false,
		user:{},
		account:{},
  },


  getters: {
    getIsAdminUser({ state }) {
			return state.isAdminUser;
    },
		getUserRoleId({ state }) {
			return state.userRoleId;
		},
		getIsAccountOwner({ state }){
			return state.isAccountOwner;
		},
		getUser({ state }){
			return state.user;
		},
		getAcount({ state }){
			return state.account;
		},
  },


  actions: {
    addProduct({ state }, product) {
    //  state.products = [...state.products, product];
    },
		addUser({ state }, user) {
		  state.user = user;
			if(user.role_id == 1){state.isAdminUser = true}else{state.isAdminUser = false}
			state.userRoleId = user.role_id
			state.isAccountOwner = user.is_account_owner;
		},
		addAccount({ state }, account) {
			state.account = account;
		},
  },



})



export default store;
