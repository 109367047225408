<template>
<f7-page name="sell">

	<f7-navbar :sliding="false" >
			<f7-nav-left>
				<f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
			</f7-nav-left>
			<f7-nav-title sliding>Voucher Recipient</f7-nav-title>
			<f7-nav-right>
			 <img style="height:45px; margin-right:5px;" src="../../assets/images/pif-logo.png" >
			</f7-nav-right>
	</f7-navbar>

<br><br>

  <f7-list no-hairlines-md inset>

		<f7-list-input
      label="Voucher Code"
			floating-label
			outline
      type="text"
      placeholder="Voucher Code"
			v-model:value="voucherCode"
    ></f7-list-input>

		<f7-list-input
			label="Recipient Full Name"
			floating-label
			outline
			type="text"
			placeholder=""
			v-model:value="recipientFullName"
		></f7-list-input>

		<f7-list-input
			floating-label
			outline
			label="Date"
			type="datepicker"
			placeholder="Date"
			v-model:value="saleDate"
			required
			:calendar-params="calendarParams"
		></f7-list-input>

		<template v-if="showVoucherValueInput" >
			<f7-list-input
				label="Voucher Value £"
				floating-label
				outline
				type="text"
				placeholder="0.00"
				v-model:value="voucherValue"
			></f7-list-input>
		</template>



<br><br><br><br>


		<f7-button class="col" color="yellow"  @click="submit()" large fill round raised>Save</f7-button>

  </f7-list>



	<br><br><br>

</f7-page>
</template>


<script>

import { ApiMixin } from '../../mixins/api-mixin.js'
import { useStore } from 'framework7-vue';
import { f7 } from 'framework7-vue';
var moment = require('moment')

export default {

	mixins: [ApiMixin],

	data() {
	  return {
	    voucherCode: '',
			recipientFullName:'',
			saleDate: [new moment()],
			voucherValue: 0,
			showVoucherValueInput:false,
			calendarParams:{
				closeOnSelect:true
			}
	  };
	},

	mounted() {

	},

	methods: {

		validate(){
			if(this.voucherCode.length < 1){f7.dialog.alert('You must enter a voucher code.'); return false;}
			if(this.recipientFullName.length < 1){f7.dialog.alert('You must enter the name of the person who has recieved the voucher (the recipient).'); return false;}
			if(this.showVoucherValueInput){
				if(!(this.voucherValue >0)){f7.dialog.alert('You must enter a value for the voucher ( how much the voucher costs to buy ).'); return false;}
			}
			return true;
		},

		submit(){
			if(!this.validate()){ return ;}

			var self = this
			f7.preloader.show();
			self.ApiLogVoucherRecipient(this.voucherCode, moment(this.saleDate[0]).unix(), this.recipientFullName, this.voucherValue).then(function(response){
				f7.preloader.hide();
				if(response.status == 1){
					f7.dialog.alert(response.message);
					self.clearForm();
				}
				else if(response.status == 2){
					f7.dialog.alert(response.message);
					self.showVoucherValueInput = true;
				}else{
					f7.dialog.alert(response.message);
				}
			});
		},

		clearForm(){
			this.voucherCode = '';
			this.saleDate =  [new moment()];
			this.recipientFullName = '';
			this.voucherValue =  0;
		}





	},


};
</script>
