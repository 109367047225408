<template>
  <f7-page name="redeem">
    <f7-navbar >
			<f7-nav-left>
				<f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
			</f7-nav-left>
			<f7-nav-title sliding>Redeem Voucher</f7-nav-title>
			<f7-nav-right>
			 <img style="height:45px; margin-right:5px;" src="../../assets/images/pif-logo.png" >
			</f7-nav-right>
		</f7-navbar>

<br><br><br><br>

		  <f7-list no-hairlines-md inset>

				<f7-list-input
					floating-label
					outline
		      label="Voucher Code"
		      type="text"
		      placeholder="Voucher Code"
					v-model:value="voucherCode"
					required
		    ></f7-list-input>

				<f7-list-input
					floating-label
					outline
					label="Redemption Amount £"
					placeholder="0.00"
					type="text"
					v-model:value="redemptionValue"
					required
					clear-button
				></f7-list-input>

				<f7-list-input
					floating-label
					outline
					label="Redemtpion Date"
					type="datepicker"
					placeholder="Sale Date"
					:value="saleDate"
					:calendar-params="calendarParams"
				></f7-list-input>

				<template v-if="showVoucherValueInput" >
					<f7-list-input
						label="Voucher Value £"
						floating-label
						outline
						type="text"
						placeholder="0.00"
						v-model:value="voucherValue"
					></f7-list-input>
				</template>

				<br><br>

				<f7-button class="col" color="yellow" @click="submit()" large fill round raised>Redeem</f7-button>

		  </f7-list>



			<br><br><br>

		</f7-page>
		</template>


		<script>
		import { f7 } from 'framework7-vue';
		import { ApiMixin } from '../../mixins/api-mixin.js';
		import { FormValidation } from '../../mixins/validation-mixin.js';
		var moment = require('moment');

		export default {

			mixins: [ApiMixin, FormValidation],

			data() {
			  return {
			    voucherCode: '',
					saleDate: [new Date()],
					redemptionValue:'',
					voucherValue: '',
					showVoucherValueInput:false,
					calendarParams:{
						closeOnSelect:true
					}
			  };
			},

			mounted() {

			},


			methods: {

				validate(){
					var numbers = /^[0-9]+$/;
					if(this.voucherCode.length < 1){f7.dialog.alert('You must enter a voucher code.'); return false;}
					if(!(this.redemptionValue > 0)){f7.dialog.alert('You must enter a value for redemption amount ( how much of the voucher was claimed ).'); return false;}
					if(this.showVoucherValueInput){
						if(!(this.voucherValue > 0)){f7.dialog.alert('You must enter a value for the voucher ( how much the voucher costs to buy ).'); return false;}
					}
					return true;
				},


				submit(){
					if(!this.validate()){ return ;}

					var self = this
					f7.preloader.show();
					self.ApiRedeemVoucher(this.voucherCode, moment(this.saleDate[0]).unix(), this.redemptionValue, this.voucherValue).then(function(response){
						f7.preloader.hide();
						if(response.status == 1){
							f7.dialog.alert(response.message);
							self.clearForm();
						}
						else if(response.status == 2){
							f7.dialog.alert(response.message);
							self.showVoucherValueInput = true;
						}else{
							f7.dialog.alert(response.message);
						}
					});
				},

				clearForm(){
					this.voucherCode = '';
					this.saleDate =  [new moment()];
					this.redemptionValue = '';
					this.voucherValue = '';
				}


			},


		};
		</script>
