<template>
<f7-page no-toolbar name="history">
  <f7-navbar >
		<f7-nav-left>
			<f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
		</f7-nav-left>
		<f7-nav-title sliding>Voucher History</f7-nav-title>
		<f7-nav-right>
		 <img style="height:45px; margin-right:5px;" src="../../assets/images/pif-logo.png" >
		</f7-nav-right>
	</f7-navbar>


	<f7-appbar >
		<div class="left">
			<f7-searchbar inline custom-search :disable-button="false" @keyup.enter="doSearch()"  v-model:value="pagination.data_query.search_term"></f7-searchbar>
		</div>
		<div class="right">
			<div class="stepper color-yellow stepper-round" style="margin-left:10px;">
				<div class="stepper-button-minus" @click="paginationPrevious()"></div>
				<div class="stepper-value">{{pagination.current_page}}/{{this.pagination.number_of_pages}}</div>
				<div class="stepper-button-plus" @click="paginationNext()"></div>
			</div>
		</div>
	</f7-appbar>


	<div v-for="voucher in vouchers"  class="card card-outline" style="background:rgba(215, 165, 177, 0.36)">
		<div class="card-header">
			<div class="col-10">{{voucher.voucher_code}}</div>
		</div>
		<div class="card-content" style="padding:15px; padding-left:15px;">
			<template v-if="voucher.redeemed_user_id" ><b>Reedeemed</b> by <b>{{voucher.redeemed_user_id}}</b> for <b>£{{voucher.redeemed_value}}</b> on <b>{{formatDate(voucher.redeemed_date)}}</b><br> </template>
			<template v-if="voucher.recipient_user_id" ><b>Voucher Recipient</b> {{voucher.recipient_name}} assigned by <b>{{voucher.recipient_user_id}}</b> on <b>{{formatDate(voucher.recipient_date)}}</b><br> </template>
			<template v-if="voucher.sold_user_id" ><b>Sold</b> by <b>{{voucher.sold_user_id}}</b> for <b>£{{voucher.voucher_value}}</b> on <b>{{formatDate(voucher.sold_date)}}</b>  </template>
		</div>
		<div class="card-footer">
			Last Updated: {{formatDate(voucher.updated_at)}}
		</div>
	</div>




</f7-page>
</template>


<script>

import { ApiMixin } from '../../mixins/api-mixin.js'
import { f7 } from 'framework7-vue';
var moment = require('moment')

export default {

	mixins: [ApiMixin],

	data() {
		return {
			vouchers: [],
			pagination:{
				per_page:20,
				current_page:1,
				number_of_pages:0,
			 	total_results:0,
				data_query:{
					search_term:'',
					filters:{
						sold:true,
						redeemed:true,
					},
					order:{
						column:'updated_at',
						direction:'desc',
					},
					date_range:{
						start_date:'',
						stop_date: '',
					},
				}
			},
		};
	},

	mounted() {
		this.getVouchers();
	},

	methods: {

		paginationNext(){
			if(this.pagination.current_page == this.pagination.number_of_pages){return;}
			this.pagination.current_page = this.pagination.current_page + 1;
			this.getVouchers();
		},

		paginationPrevious(){
			if(this.pagination.current_page == 1){return;}
			this.pagination.current_page = this.pagination.current_page - 1;
			this.getVouchers();
		},

		doSearch(){
			this.pagination.current_page = 1;
			this.getVouchers();
		},

		getVouchers(){
			var self = this
			f7.preloader.show();
			self.ApiGetVouchers(self.pagination).then(function(response){
				f7.preloader.hide();
				if(response.status == 1 ){
				 self.vouchers = response.data
				 self.pagination = response.pagination
				 console.log(self.vouchers)
			  }else{
					console.log('voucher endpoint did not reply success')
			  }
			});
		},

		formatDate(date){
			return moment(date).format("Do MMM YY  h:mm a")
		}

	},


};
</script>
