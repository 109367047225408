import { request } from 'framework7';
import { f7 } from 'framework7-vue';

export const ApiMixin = {

  data() {
    return {
      api_url: '',
    }
  },

	mounted(){
		this.api_url = f7.store.state.api_url
		if(localStorage.access_token){
			f7.request.setup({headers: {'Authorization':'Bearer '+localStorage.access_token, 'Accept':'application/json'} })
		}
	},

	computed: { },

	methods: {

		//*************************************************************
		ApiPreloadCoreData(){
			var self =  this

			// get users data and apply to store
			return new Promise((resolve, reject) => {
				f7.request.get( self.api_url+'user/this-user').then((response) => {
					f7.preloader.hide();
					response = JSON.parse(response.data);
					console.log(response);
					if(response.status == 1){
					  //	f7.store.state.user = response.data.user;
						f7.store.dispatch('addUser', response.data.user )
						f7.store.dispatch('addAccount', response.data.account )
						resolve(true);
					}else{
						resolve(false);
					}
				}).catch(function (err) {
			    console.log(err.xhr)
			    console.log(err.status)
			    console.log(err.message)
					f7.preloader.hide();
					localStorage.access_token = ''
					f7.dialog.alert(JSON.parse(err.xhr.response).message);
					resolve(false);
			  });
			});


		},
		//*************************************************************

		//*************************************************************
		ApiRegister(request_params){
		  var self = this
		  // return a promis
		  return new Promise((resolve, reject) => {
		  	request.post(self.api_url+'user/register', request_params).then((response) => {
					response = JSON.parse(response.data);
		      if(response.status == 1){
	          self.setAccessToken(response.data);
						resolve(response);
		      }else{
		        resolve(response);
		      }
		    });

		  });

		},
		//*************************************************************#

		//*************************************************************
		ApiSaveUser(request_params){
			var self = this
			// return a promis
			return new Promise((resolve, reject) => {
				request.post(self.api_url+'user/save-user', request_params).then((response) => {
					response = JSON.parse(response.data);
					resolve(response);
				});
			});

		},
		//*************************************************************

		//*************************************************************
		ApiDeleteUser(request_params){
			var self = this
			// return a promis
			return new Promise((resolve, reject) => {
				request.post(self.api_url+'user/delete-user', request_params).then((response) => {
					response = JSON.parse(response.data);
					resolve(response);
				});
			});

		},
		//*************************************************************

		//*************************************************************
		ApiSignIn(username,password){
		  const self = this;
			var endpoint = self.api_url+'user/signin';

			return new Promise((resolve, reject) => {

		    request.post(endpoint, {email:username, password:password}).then((response) => {
		        response = JSON.parse(response.data);
		        if(response.status == 1){
		           if (localStorage) {
		        			self.setAccessToken(response.data);
									console.log('login was successful at api');
									resolve(true)
		           }else{
		             f7.dialog.alert('this app will not work unless local storage is enabled');
								 resolve(false)
		           }
		        }else{
		          f7.dialog.alert('could not find an account with that email and password');
							resolve(false)
		        }
		    }).catch(function (err) {
					f7.dialog.alert('could not find an account with that email and password. If you have recently signed up, make sure you have also verified your email by clicking on the link in the email you where sent.');
					resolve(false);
			  });

			});

		},
		//*************************************************************

		//*************************************************************
		ApiSignOut(username,password){
			const self = this;
			var endpoint = self.api_url+'user/signout';

			return new Promise((resolve, reject) => {

				request.get(endpoint).then((response) => {
					response = JSON.parse(response.data);
					console.log(response);
					if(response.status == 1){
						self.revokeAccessToken();
						console.log('sign out successful at api');
						resolve(true)
					}else{
						resolve(false)
					}
				});

			});

		},
		//*************************************************************


		//*************************************************************
		setAccessToken(token){
		    // remember / store the token
		    localStorage.access_token = token;
		    // set the request to have token in header
		    request.setup({
		      headers: {
		        'Authorization':'Bearer '+token,
		        'Accept':'application/json'
		      }
		    })
		    console.log(localStorage.access_token);
		},
		//*************************************************************

		//*************************************************************
		revokeAccessToken(){
				// remember / store the token
				localStorage.access_token = '';
				// set the request to have token in header
				request.setup({
					headers: {
						'Authorization':'Bearer '+'',
						'Accept':'application/json'
					}
				})

		},
		//*************************************************************




		//############################################################
		//############################################################



		//*************************************************************
		ApiGetVouchers(pagination, filters, order, date_range){
			var self = this
		  // return a promis
		  return new Promise((resolve, reject) => {
			  request.get(self.api_url+'voucher', {pagination:pagination} ).then((response) => {
					response = JSON.parse(response.data);
			    resolve(response);
			  });
		  });

		},
		//*************************************************************


		//*************************************************************
		ApiGetVoucherBatches(pagination, filters, order, date_range){
			var self = this
		  // return a promis
		  return new Promise((resolve, reject) => {
			  request.get(self.api_url+'voucher/batches', {pagination:pagination} ).then((response) => {
					response = JSON.parse(response.data);
			    resolve(response);
			  });
		  });

		},
		//*************************************************************


		//*************************************************************
		ApiGetUsers(){
			var self = this
		  // return a promis
		  return new Promise((resolve, reject) => {
			  request.get(self.api_url+'user/users' ).then((response) => {
					response = JSON.parse(response.data);
			    resolve(response);
			  });
		  });

		},
		//*************************************************************


		//*************************************************************
		ApiSellVoucher(code, date, voucherValue){
			var self = this
		  // return a promis
		  return new Promise((resolve, reject) => {
			  request.post(self.api_url+'voucher/sell', {code:code, timestamp:date, voucherValue:voucherValue} ).then((response) => {
		      response = JSON.parse(response.data);
			  	resolve(response);
			  });
		  });
		},
		//*************************************************************

		//*************************************************************
		ApiRedeemVoucher(code, date, redemptionValue, voucherValue){
			var self = this
			// return a promis
			return new Promise((resolve, reject) => {
				request.post(self.api_url+'voucher/redeem', {code:code, timestamp:date, redemptionValue:redemptionValue, voucherValue:voucherValue} ).then((response) => {
					response = JSON.parse(response.data);
					resolve(response);
				});
			});
		},
		//*************************************************************

		//*************************************************************
		ApiLogVoucherRecipient(code, date, recipientFullName, voucherValue ){
			var self = this
			// return a promis
			return new Promise((resolve, reject) => {
				request.post(self.api_url+'voucher/recipient', {code:code, timestamp:date, recipientFullName:recipientFullName, voucherValue:voucherValue} ).then((response) => {
					response = JSON.parse(response.data);
					resolve(response);
				});
			});
		},
		//*************************************************************

		//*************************************************************
		ApiCreateVoucherBatch(batch_name, batch_size, voucher_value, batch_notes){
			var self = this
			// return a promis
			return new Promise((resolve, reject) => {
				request.post(self.api_url+'voucher/create-batch', {batch_name:batch_name, batch_size:batch_size, voucher_value:voucher_value, batch_notes:batch_notes} ).then((response) => {
					response = JSON.parse(response.data);
					resolve(response);
				});
			});
		},
		//*************************************************************

		//*************************************************************
		ApiDownloadVoucherBatch(batch_id){
			var self = this
			// return a promis
			return new Promise((resolve, reject) => {
				request.get(self.api_url+'voucher/download-batch', {batch_id:batch_id} ).then((response) => {
					response = JSON.parse(response.data);
					if(response.status == 1){
						resolve(true);
					}else{
						resolve(false);
					}
				});
			});
		},
		//*************************************************************

		//*************************************************************
		ApiSendSupportMessage(message){
			var self = this
			// return a promis
			return new Promise((resolve, reject) => {
				request.post(self.api_url+'support/support-message', {message:message} ).then((response) => {
					response = JSON.parse(response.data);
					resolve(response);
				});
			});
		},
		//*************************************************************

		//*************************************************************
		ApiGetAccountsOverview(pagination){
			var self = this
		  // return a promis
		  return new Promise((resolve, reject) => {
			  request.get(self.api_url+'accounts/overview', {pagination:pagination} ).then((response) => {
					response = JSON.parse(response.data);
			    resolve(response);
			  });
		  });

		},
		//*************************************************************





	}, // End Methods
}
