<template>
<f7-page name="Accounts">

    <f7-navbar >
			<f7-nav-left>
				<f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
			</f7-nav-left>
			<f7-nav-title sliding>Voucher Batches</f7-nav-title>
			<f7-nav-right>
			 <img style="height:45px; margin-right:5px;" src="../../../assets/images/pif-logo.png" >
			</f7-nav-right>
		</f7-navbar>

		<f7-appbar >
			<div class="left">

			</div>
			<div class="right">
				<div class="stepper color-yellow stepper-round" style="margin-left:0px;">
					<div class="stepper-button-minus" @click="paginationPrevious()"></div>
					<div class="stepper-value">{{pagination.current_page}}/{{this.pagination.number_of_pages}}</div>
					<div class="stepper-button-plus" @click="paginationNext()"></div>
				</div>
			</div>
		</f7-appbar>

		<div v-for="account in accounts_overview"  class="card card-outline" style="background:rgba(215, 165, 177, 0.36)">
		  <div class="card-header">
		    <div class="col-10">Account Name: {{account.account_name}}</div>
				<div class="col-30">ID: {{account.id}}</div>
			</div>
		  <div class="card-content" style="padding:15px; padding-left:15px;">
				Email: {{account.email}} <br><br>
				<div v-for="voucher in account.vouchers" >
					<div class="">Code: {{voucher.voucher_code}}</div>
					<div class="" v-if="voucher.sold_date" >Sold: {{voucher.sold_date}}</div>
					<div class="" v-if="voucher.sold_date" >Sold Value: {{voucher.sold_value}}</div>
					<div class="" v-if="voucher.redeemed_date" >Redeemed: {{voucher.redeemed_date}}</div>
					<div class="" v-if="voucher.redeemed_date" >Redeemed Value: {{voucher.redeemed_value}}</div>
					<div class="">---------------------------------------</div>
				</div>
		  </div>
		  <div class="card-footer">
				Date Account Created: {{account.created_at}}
			</div>
		</div>




</f7-page>
</template>


<script>

	import { ApiMixin } from '../../../mixins/api-mixin.js'
	import { f7 } from 'framework7-vue';
	var moment = require('moment')

	export default {

		mixins: [ApiMixin],

		data() {
		  return {
				accounts_overview: [],
				selectedPage:1,
				pagination:{
					per_page:20,
					current_page:1,
					number_of_pages:0,
				 	total_results:0,
					data_query:{
						search_term:'',
						filters:{
							sold:true,
							redeemed:true,
						},
						order:{
							column:'date_codes_generated',
							direction:'desc',
						},
						date_range:{
							start_date:'',
							stop_date: '',
						},
					}
				},
		  };
		},

		mounted() {
			this.getGetAccountsOverview();
		},


		methods: {

			paginationNext(){
				if(this.pagination.current_page == this.pagination.number_of_pages){return;}
				this.pagination.current_page = this.pagination.current_page + 1;
				this.getGetAccountsOverview();
			},

			paginationPrevious(){
				if(this.pagination.current_page == 1){return;}
				this.pagination.current_page = this.pagination.current_page - 1;
				this.getGetAccountsOverview();
			},

			getGetAccountsOverview(){
				var self = this
				f7.preloader.show();
				self.ApiGetAccountsOverview(self.pagination).then(function(response){
					f7.preloader.hide();
					if(response.status == 1){
					 self.accounts_overview = response.data
					 console.log(self.accounts_overview)
					 self.pagination = response.pagination
					}else{
						console.log('endpoint did not reply success')
					}
				});
			},

			downloadBatch(batchId){
				var self = this
				f7.preloader.show();
				this.ApiDownloadVoucherBatch(batchId).then(function(success){
					f7.preloader.hide();
					if(success){
						f7.dialog.alert('Check your email. A csv file with the voucher codes has been sent to you.');
					}
				});
			}


		},


	};
</script>
