export const FormValidation = {

	  data() {
	    return {
				formValidation:{
					errors:[],
				}
	    }
	  },

	  computed: {},

		mounted(){
			// this.FvIsNumber('first_name', 'First Name', 'felix', '') // this is here for testing only
		},

	  methods: {






			FvClearErrors(){
				this.formValidation.errors = []
			},

			FvAddError(fieldIdentifier, fieldLabel, value, errorMessage){
				// dont add same error more than once
				if(this.FvErrorExists(fieldIdentifier, fieldLabel, value, errorMessage)){return}
				// build error object
				var errorObj = {
												fieldLabel: fieldLabel,
												fieldIdentifier: fieldIdentifier,
												value: value,
												errorMessage: errorMessage,
											}
				// add error object
				this.formValidation.errors.push(errorObj);
			},

			FvErrorExists(fieldIdentifier, fieldLabel, value, errorMessage){
				for(var x = 0; x < this.formValidation.errors.length; x++){
					if(this.formValidation.errors[x].fieldIdentifier == fieldIdentifier && this.formValidation.errors[x].errorMessage == errorMessage){return true}
				}
				return false;
			},

			FvFieldHasError(fieldIdentifier){
				for(var x = 0; x < this.formValidation.errors.length; x++){
					if(this.formValidation.errors[x].fieldIdentifier == fieldIdentifier){
						return true
					}
				}
				return false;
			},






			// validate ***************************
			FvIsNumber(fieldIdentifier, fieldLabel, value, errorMessage){
				var numbers = /^[0-9]+$/;
			  if(value.match(numbers)){
					return true
				}else{
					if(errorMessage == ''){errorMessage += 'must be a number'}
					this.FvAddError(fieldIdentifier, fieldLabel, value, errorMessage)
					return false
				}
			},
			FvIsLetters(fieldIdentifier, fieldLabel, value, errorMessage){
				if(	/^[a-zA-Z]+$/.test(value)){
					return true
				}else{
					if(errorMessage == ''){errorMessage += 'must be letters only'}
					this.FvAddError(fieldIdentifier, fieldLabel, value, errorMessage)
					return false
				}
			},
			FvIsNumbersAndLetters(fieldIdentifier, fieldLabel, value, errorMessage){
				if(/^[a-z0-9]+$/.test(value)){
					return true
				}else{
					if(errorMessage == ''){errorMessage += 'must be letters and numbers only'}
					this.FvAddError(fieldIdentifier, fieldLabel, value, errorMessage)
					return false
				}
			},
			FvIsEmail(fieldIdentifier, fieldLabel, value, errorMessage){
				if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)){
					return true
				}else{
					if(errorMessage == ''){errorMessage += 'must be an email address'}
					this.FvAddError(fieldIdentifier, fieldLabel, value, errorMessage)
					return false
				}
			},
			FvIsMaxLength(fieldIdentifier, fieldLabel, value, errorMessage, param){
				if(value.length <= param){
					return true
				}else{
					if(errorMessage == ''){errorMessage += 'must be no more than '+param+' characters long' }
					this.FvAddError(fieldIdentifier, fieldLabel, value, errorMessage)
					return false
				}
			},
			FvIsMinLength(fieldIdentifier, fieldLabel, value, errorMessage, param){
				if(value.length >= param){
					return true
				}else{
					if(errorMessage == ''){errorMessage += 'must be at least '+param+' characters long' }
					this.FvAddError(fieldIdentifier, fieldLabel, value, errorMessage)
					return false
				}
			},
			FvStringIsNotEmpty(fieldIdentifier, fieldLabel, value, errorMessage){

				if(value){
					if(value.trim().length > 0){
						return true
					}
				}

				if(errorMessage == ''){errorMessage += 'must not be left blank' }
				this.FvAddError(fieldIdentifier, fieldLabel, value, errorMessage)
				return false

			},

			FvArrayIsNotEmpty(fieldIdentifier, fieldLabel, value, errorMessage){
				if(value.length > 0){
					return true
				}else{
					if(errorMessage == ''){errorMessage += 'make at least one selection' }
					this.FvAddError(fieldIdentifier, fieldLabel, value, errorMessage)
					return false
				}
			},

			FvObjectIsNotEmpty(fieldIdentifier, fieldLabel, value, errorMessage){
				if(JSON.stringify(value)!=JSON.stringify({})){
					return true
				}else{
					if(errorMessage == ''){errorMessage += 'make at least one selection' }
					this.FvAddError(fieldIdentifier, fieldLabel, value, errorMessage)
					return false
				}
			},

			FvMatchPasswords(fieldIdentifier, fieldLabel, value, errorMessage, param){
				if(param == value){
					return true
				}else{
					if(errorMessage == ''){errorMessage += 'your new and confirmed passwords must match' }
					this.FvAddError(fieldIdentifier, fieldLabel, value, errorMessage)
					return false
				}
			},


			FvRecurlyTransactionErrors(error){

			}
			// ************************************


	  },
}
