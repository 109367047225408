<template>
<f7-page name="Voucher Batches">

    <f7-navbar >
			<f7-nav-left>
				<f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
			</f7-nav-left>
			<f7-nav-title sliding>Voucher Batches</f7-nav-title>
			<f7-nav-right>
			 <img style="height:45px; margin-right:5px;" src="../../../assets/images/pif-logo.png" >
			</f7-nav-right>
		</f7-navbar>

		<f7-appbar >
			<div class="left">
			
			</div>
			<div class="right">
				<div class="stepper color-yellow stepper-round" style="margin-left:0px;">
					<div class="stepper-button-minus" @click="paginationPrevious()"></div>
					<div class="stepper-value">{{pagination.current_page}}/{{this.pagination.number_of_pages}}</div>
					<div class="stepper-button-plus" @click="paginationNext()"></div>
				</div>
			</div>
		</f7-appbar>

		<div v-for="batch in voucher_batches"  class="card card-outline" style="background:rgba(215, 165, 177, 0.36)">
		  <div class="card-header">
		    <div class="col-10">Batch ID: {{batch.id}}</div>
		    <div class="col-60">{{batch.name}}</div>
				<div class="col-30">Codes: {{batch.batch_size}}</div>
			</div>
		  <div class="card-content" style="padding:15px; padding-left:15px;">
				 {{batch.notes}}
		  </div>
		  <div class="card-footer">
				Date Created: {{batch.date_codes_generated}} <f7-button color="blue" @click="downloadBatch(batch.id)" fill round >Download</f7-button>
			</div>
		</div>




</f7-page>
</template>


<script>

	import { ApiMixin } from '../../../mixins/api-mixin.js'
	import { f7 } from 'framework7-vue';
	var moment = require('moment')

	export default {

		mixins: [ApiMixin],

		data() {
		  return {
				voucher_batches: [],
				selectedPage:1,
				pagination:{
					per_page:20,
					current_page:1,
					number_of_pages:0,
				 	total_results:0,
					data_query:{
						search_term:'',
						filters:{
							sold:true,
							redeemed:true,
						},
						order:{
							column:'date_codes_generated',
							direction:'desc',
						},
						date_range:{
							start_date:'',
							stop_date: '',
						},
					}
				},
		  };
		},

		mounted() {
			this.getVoucherBatches();
		},


		methods: {

			paginationNext(){
				if(this.pagination.current_page == this.pagination.number_of_pages){return;}
				this.pagination.current_page = this.pagination.current_page + 1;
				this.getVoucherBatches();
			},

			paginationPrevious(){
				if(this.pagination.current_page == 1){return;}
				this.pagination.current_page = this.pagination.current_page - 1;
				this.getVoucherBatches();
			},

			getVoucherBatches(){
				var self = this
				f7.preloader.show();
				self.ApiGetVoucherBatches(self.pagination).then(function(response){
					f7.preloader.hide();
					if(response.status == 1){
					 self.voucher_batches = response.data
					 console.log(self.voucher_batches)
					 self.pagination = response.pagination
					}else{
						console.log('voucher batches endpoint did not reply success')
					}
				});
			},

			downloadBatch(batchId){
				var self = this
				f7.preloader.show();
				this.ApiDownloadVoucherBatch(batchId).then(function(success){
					f7.preloader.hide();
					if(success){
						f7.dialog.alert('Check your email. A csv file with the voucher codes has been sent to you.');
					}
				});
			}


		},


	};
</script>
