<template>
<f7-page no-toolbar name="settings" >

	<f7-navbar :sliding="false" >
			<f7-nav-left>
				<f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
			</f7-nav-left>
			<f7-nav-title sliding>Users</f7-nav-title>
			<f7-nav-right>
			 <img style="height:45px; margin-right:5px;" src="../../assets/images/pif-logo.png" >
			</f7-nav-right>
	</f7-navbar>


	<f7-block strong  no-hairlines>
		<f7-button  @click="addUser()" fill round >Add new user</f7-button>
	</f7-block>

	<div v-for="user in users"  class="card card-outline" style="background:rgba(215, 165, 177, 0.36)">
		<div class="card-header">
		 {{user.name}} {{user.surname}}

		</div>
		<div class="card-content" style="padding:15px; padding-left:15px;">
			{{user.email}}
		</div>
		<div class="card-footer">
			Last Updated: {{formatDate(user.updated_at)}}
			<f7-button  @click="editUser(user)" fill round >Edit</f7-button>
			<f7-button  @click="deleteUser(user)" fill round >Delete</f7-button>
		</div>
	</div>



	<br><br><br>

</f7-page>
</template>


<script>

import { ApiMixin } from '../../mixins/api-mixin.js'
import { f7 } from 'framework7-vue';
var moment = require('moment')

export default {

	mixins: [ApiMixin],

	data() {
	  return {
			users:[],
	  };
	},

	mounted(){
		this.getUsers();
	},


	methods: {

		getUsers(){
			var self = this
			f7.preloader.show();
			self.ApiGetUsers().then(function(response){
				f7.preloader.hide();
				if(response.status == 1){
					self.users = response.data
				}
			});
		},


		addUser(){
			f7.views.main.router.navigate('/user-user/',{props: {user: null}})
		},

		editUser(user){
			f7.views.main.router.navigate('/user-user/', {props: {user: user}})
		},

		deleteUser(user){
			var self = this
			f7.dialog.confirm('Are you sure you want to delete this user?', function () {
				f7.preloader.show();
				self.ApiDeleteUser({id:user.id}).then(function(response){
					f7.preloader.hide();
					if(response.status == 1){
						self.getUsers();
						f7.dialog.alert(response.message);
					}else{
						f7.dialog.alert(response.message);
					}
				});
			});
		},

		formatDate(date){
			return moment(date).format("Do MMMM YYYY")
		}

	},


};
</script>
