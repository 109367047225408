<template>
<f7-page  name="settings" >

	<f7-navbar :sliding="false" >
			<f7-nav-left>
				<f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
			</f7-nav-left>
			<f7-nav-title sliding>Account</f7-nav-title>
			<f7-nav-right>
			 <img style="height:45px; margin-right:5px;" src="../../assets/images/pif-logo.png" >
			</f7-nav-right>
	</f7-navbar>


  <f7-list no-hairlines-md inset>

		<f7-list-input
      label="Name"
      type="text"
      placeholder="Name"
			v-model:value="user.name"
			disabled
    ></f7-list-input>

		<f7-list-input
			label="Surname"
			type="text"
			placeholder="Surname"
			v-model:value="user.surname"
			disabled
		></f7-list-input>

		<f7-list-input
			label="email"
			type="text"
			placeholder="email"
			v-model:value="user.email"
			disabled
		></f7-list-input>

		<f7-list-input
			label="Date Joined"
			type="text"
			placeholder="Date Joined"
			:value="formatDate(user.created_at)"
			disabled
		></f7-list-input>

		<f7-list-input
			label="Business Name"
			type="text"
			placeholder="Business Name"
			:value="account.name"
			disabled
		></f7-list-input>

		<br><br><br><br><br><br>

		<!-- <f7-button class="col" @click="submit()" large fill round raised>Submit</f7-button> -->

  </f7-list>

	<f7-toolbar bottom>
    <f7-link @click="goToVillageCommon()">APP Produced by</f7-link>
    <f7-link @click="goToVillageCommon()" >www.TheVillageCommon.co.uk</f7-link>
  </f7-toolbar>

	<br><br><br>

</f7-page>
</template>


<script>

import { ApiMixin } from '../../mixins/api-mixin.js'
import { f7 } from 'framework7-vue';
var moment = require('moment')

export default {

	mixins: [ApiMixin],

	data() {
	  return {
			user:{},
			account:{},
	  };
	},


	mounted() {
		this.user = f7.store.state.user
		this.account = f7.store.state.account
	},


	methods: {


		goToVillageCommon(){
			window.open( "https://thevillagecommon.co.uk" , "_blank");
		},

		formatDate(date){
			return moment(date).format("Do MMMM YYYY")
		}

	},


};
</script>
