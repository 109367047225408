<template>
<f7-page no-toolbar name="settings" >

	<f7-navbar :sliding="true" back-link="Back">
			<f7-nav-title sliding>User</f7-nav-title>
			<f7-nav-right>
			 <img style="height:45px; margin-right:5px;" src="../../assets/images/pif-logo.png" >
			</f7-nav-right>
	</f7-navbar>

	<f7-list no-hairlines-md inset>

		<f7-list-input
			label="Name"
			type="text"
			placeholder="Your name"
			v-model:value="name"
		></f7-list-input>

		<f7-list-input
			label="Surname"
			type="text"
			placeholder="Your surname"
			v-model:value="surname"
		></f7-list-input>

		<f7-list-input
		label="email"
		type="text"
		placeholder="Your email"
		v-model:value="email"
		@input="email = $event.target.value"
		></f7-list-input>

		<f7-list-input
		label="Password"
		type="password"
		v-model:placeholder="passwordPlaceholder"
		v-model:value="password"
		></f7-list-input>




		<f7-block strong  no-hairlines>
			<f7-button  @click="saveUser()" fill round >Save</f7-button>
		</f7-block>


  </f7-list>


	<br><br><br>

</f7-page>
</template>


<script>

import { ApiMixin } from '../../mixins/api-mixin.js'
import { FormValidation } from '../../mixins/validation-mixin.js';
import { f7 } from 'framework7-vue';
var moment = require('moment')

export default {

	mixins: [ApiMixin,FormValidation],

	data() {
	  return {
			user_id:0,
			email: '',
			password: '',
			name: '',
			surname: '',
			password: '',
			creatingNewUser:true,
			passwordPlaceholder:'Password',
	  };
	},

	props: {
		f7route: Object,
		f7router: Object,
		user:Object,
	},

	mounted(){
		console.log(this.user);
		if(this.user){
			this.user_id = this.user.id
			this.email = this.user.email;
			this.name = this.user.name;
			this.surname = this.user.surname;
			this.creatingNewUser = false;
			this.passwordPlaceholder  = 'password to change to'
		}
	},


	methods: {

		validate(){
			if(this.name.length < 1){f7.dialog.alert('You must enter a name.'); return false;}
			if(this.surname.length < 1){f7.dialog.alert('You must enter a surname.'); return false;}
			if(this.email.length < 1 || !this.FvIsEmail('fieldIdentifier', 'fieldLabel', this.email, 'errorMessage')){f7.dialog.alert('You must enter a valid email address.'); return false;}

			if(this.creatingNewUser){
				if(this.password.length < 6){f7.dialog.alert('You must enter a password of at least six characters long.'); return false;}
			}
			return true;
		},


		saveUser() {
			if(!this.validate()){ return ;}

			var self = this;

			var request_params = {
				    user_id:this.user_id,
						email:self.email,
						password: self.password,
						name: self.name,
						surname: self.surname,
					};

			 this.ApiSaveUser(request_params).then(function(response){
				 console.log(response);
				 if(response.status == 1){
					 f7.dialog.alert(response.message);
					 f7.views.main.router.navigate('/user-users/');
				 }else{
					 f7.dialog.alert(response.message);
				 }
			 });

		},


		formatDate(date){
			return moment(date).format("Do MMMM YYYY")
		}

	},


};
</script>
