<template>
<f7-app v-bind="f7params" >

  <!-- Left panel with cover effect-->
  <f7-panel left cover  v-model:opened="rightPanelIsOpen" >
    <f7-view>
			<f7-page>
        <f7-navbar title="Menu"></f7-navbar>
        <f7-block-title>Log Vouchers</f7-block-title>
        <f7-list>
          <f7-list-item v-if="getUserRoleId <= 3" link="/vouchers-home/"     view=".view-main"  panel-close title="Log Vouchers"></f7-list-item>
					<f7-list-item v-if="getUserRoleId >= 4 || getUserRoleId <= 2 "  link="/voucher-recipient/"  view=".view-main"  panel-close title="Log Voucher Recipient"></f7-list-item>
					<f7-list-item link="/voucher-history/"  view=".view-main"  panel-close title="Voucher History"></f7-list-item>
        </f7-list>

				<template v-if="getIsAdminUser">
					<f7-block-title>Voucher Codes</f7-block-title>
					<f7-list >
						<f7-list-item link="/admin/vouchers/batches/"  view=".view-main" panel-close title="Voucher Batches"></f7-list-item>
						<f7-list-item link="/admin/vouchers/create-batch/"  view=".view-main" panel-close title="Create Batch"></f7-list-item>
						<f7-list-item link="/admin/accounts/"  view=".view-main" panel-close title="Accounts Overview"></f7-list-item>
					</f7-list>
				</template>

				<f7-block-title>Account</f7-block-title>
				<f7-list>
					<f7-list-item link="/user-settings/"  view=".view-main" panel-close title="Account"></f7-list-item>
					<f7-list-item v-if="getIsAccountOwner" link="/user-users/"  view=".view-main" panel-close title="Users"></f7-list-item>
					<f7-list-item link="/support/support-message/"  view=".view-main" panel-close title="Report a Problem"></f7-list-item>
					<f7-list-item link="#" @click="signOut()"  panel-close title="Log Out"></f7-list-item>

				</f7-list>
      </f7-page>
    </f7-view>
  </f7-panel>


  <f7-view id="main-tab" name="main" main  url="/"></f7-view>


	<!-- Popup -->
	<f7-popup id="my-popup">
		<f7-view>
			<f7-page>
				<f7-navbar title="Popup">
					<f7-nav-right>
						<f7-link popup-close>Close</f7-link>
					</f7-nav-right>
				</f7-navbar>
				<f7-block>
					<p>Popup content goes here.</p>
				</f7-block>
			</f7-page>
		</f7-view>
	</f7-popup>








</f7-app>
</template>




<script>
  import { ref, onMounted } from 'vue';
  import { f7, f7ready, views, useStore} from 'framework7-vue';
  import { getDevice }  from 'framework7/lite-bundle';
  import cordovaApp from '../js/cordova-app.js';
  import routes from '../js/routes.js';
  import store from '../js/store';
	import { ApiMixin } from '../mixins/api-mixin.js'

  export default {
    setup() {

      const device = getDevice();
			const getIsAdminUser = useStore(store,'getIsAdminUser');
			const getIsAccountOwner = useStore(store,'getIsAccountOwner');
			const getUserRoleId = useStore(store,'getUserRoleId');


      // Framework7 Parameters
      const f7params = {
        name: 'PIF Gift Vouchers', // App name
        theme: 'auto', // Automatic theme detection
        id: 'io.framework7.myapp', // App bundle ID
        // App store
        store: store,
        // App routes
        routes: routes,
        // Input settings
        input: {
          scrollIntoViewOnFocus: device.cordova && !device.electron,
          scrollIntoViewCentered: device.cordova && !device.electron,
        },
        // Cordova Statusbar settings
        statusbar: {
          iosOverlaysWebView: true,
          androidOverlaysWebView: false,
        },
      };

      onMounted(() => {
        f7ready(() => {
          // Init cordova APIs (see cordova-app.js)
          if (device.cordova) {
            cordovaApp.init(f7);
          }
        });
      });

      return {
        f7params,
				getIsAdminUser,
				getIsAccountOwner,
				getUserRoleId,
      }

    },

		mixins: [ApiMixin],

		data() {
      return {
				rightPanelIsOpen:false,
      };
    },

		computed:{

		},

		mounted(){

		},

		methods: {

			signOut(){
				this.ApiSignOut().then(function(success){
					if(success){
					 f7.views.main.router.navigate('/user/login/')
					}
				});
			}

		},





  }
</script>
