<template>
  <f7-page name="Voucher Batch">
    <f7-navbar >
			<f7-nav-left>
				<f7-link icon-ios="f7:menu" icon-aurora="f7:menu" icon-md="material:menu" panel-open="left"></f7-link>
			</f7-nav-left>
			<f7-nav-title sliding>Support Message</f7-nav-title>
			<f7-nav-right>
			 <img style="height:45px; margin-right:5px;" src="../../assets/images/pif-logo.png" >
			</f7-nav-right>
		</f7-navbar>


		<f7-list no-hairlines-md inset>

			<f7-list-input
				floating-label
				outline
				resizable=true
				label="Support Message"
				placeholder="Write a message to the app team here."
				v-model:value="message"
				type="textarea"
			></f7-list-input>

			<br><br>

			<f7-button class="col"  @click="sendMessage()" color="yellow"  large fill round raised>Send</f7-button>
			<br><br>



		</f7-list>


		<br><br><br>


</f7-page>
</template>


		<script>

		import { ApiMixin } from '../../mixins/api-mixin.js'
		import { f7 } from 'framework7-vue';
		var moment = require('moment')

		export default {

			mixins: [ApiMixin],

			data() {
			  return {
			    message: '',
			  };
			},

			mounted() {

			},


			methods: {

				validate(){
					if(this.message.length < 1){f7.dialog.alert('You must enter a message.'); return false;}
					return true;
				},

		    sendMessage(isValid){
					if(!this.validate()){ return ;}

					var self = this
					f7.preloader.show();
					this.ApiSendSupportMessage(this.message).then(function(response){
						f7.preloader.hide();
						if(response.status == 1){
							f7.dialog.alert(response.message);
							self.message = '';
						}
					});
				},


			},


		};
		</script>
