<template>
  <f7-page no-toolbar no-navbar no-swipeback class="register-page" login-screen>
		<br>

		<div class="logo-image">
			<img style="width:150px; " src="../../assets/images/pif-logo-clean.png" >
		</div>

    <f7-login-screen-title style="color:#c83c64;">REGISTER</f7-login-screen-title>

    <f7-list form>

        <f7-list-input
					outline
					floating-label
					label="Name"
          type="text"
          placeholder="Name"
          v-model:value="name"
        ></f7-list-input>

        <f7-list-input
				outline
				floating-label
          label="Surname"
          type="text"
          placeholder=""
          v-model:value="surname"
        ></f7-list-input>

        <f7-list-input
				outline
				floating-label
        label="email"
        type="text"
        placeholder=""
        v-model:value="email"
        ></f7-list-input>

        <f7-list-input
				outline
				floating-label
        label="Password"
        type="password"
        placeholder=""
        v-model:value="password"
        ></f7-list-input>

				<f7-list-input
				outline
				floating-label
				label="Business Name"
				type="text"
				placeholder=""
				v-model:value="business_name"
				></f7-list-input>

				<f7-list-input
				outline
				floating-label
					label="How will you use the app ?"
					type="select"
					placeholder=""
					v-model:value="role_type"
				>
					<f7-icon icon="demo-list-icon" slot="media"></f7-icon>
					<option value="sell_redeem">to sell and redeem vouchers</option>
					<option value="add_recipient">to assign recipients to vouchers</option>
				</f7-list-input>

<br>
				<f7-button style="margin:10px;" @keyup.enter="register()"  @click="register()" large fill round >Register</f7-button>
<br>
				<f7-button  style="margin:10px;" @click="signIn()"    >Sign In</f7-button>


    </f7-list>







  </f7-page>
</template>

<script>


import { ApiMixin } from '../../mixins/api-mixin.js'
import { FormValidation } from '../../mixins/validation-mixin.js';
import { f7 } from 'framework7-vue';

 export default {

   mixins: [ApiMixin,FormValidation],

    data() {
      return {
        email: '',
        password: '',
        name: '',
        surname: '',
				business_name:'',
				role_type:'',
      };
    },

    mounted() {


    },

    methods: {

			validate(){
				if(this.name.length < 1){f7.dialog.alert('You must enter a name.'); return false;}
				if(this.surname.length < 1){f7.dialog.alert('You must enter a surname.'); return false;}
				if(this.email.length < 1 || !this.FvIsEmail('fieldIdentifier', 'fieldLabel', this.email, 'errorMessage')){f7.dialog.alert('You must enter a valid email address.'); return false;}
				if(this.password.length < 6){f7.dialog.alert('You must enter a password of at least six characters long.'); return false;}
				if(this.business_name.length < 3){f7.dialog.alert('You must enter a business / organisation name for the account.'); return false;}
				if(this.role_type.length < 1){f7.dialog.alert('You must select how you will use the app.'); return false;}

				return true;
			},


			register() {
				if(!this.validate()){ return ;}
				f7.preloader.show();
				var self = this;

				var request_params = {
	                email:self.email,
	                password: self.password,
	                c_password: self.password,
	                name: self.name,
	                surname: self.surname,
									business_name: self.business_name,
									role_type:self.role_type,
	            };

         this.ApiRegister(request_params).then(function(response){
					 console.log(response);
					 f7.preloader.hide();
					 if(response.status == 1){
						 f7.dialog.alert(response.message);
						 f7.views.main.router.navigate('/user/login/');
					 }else{
						 f7.dialog.alert('Woops that did not work. '+response.message);
					 }
				 });

      },

			signIn(){
					f7.views.main.router.navigate('/user/login/')
			},



    },
};
</script>
<style>
.register-page{
	background-image: url("../../assets/images/swish-background-doodle2.svg");
	background-size: cover;
	background-repeat:no-repeat;
	background-position: center center;
}

.logo-image{
	margin-left:calc(50% - 75px);
	width:150px;
}

</style>
