<template>
  <f7-page no-toolbar no-navbar no-swipeback login-screen >

		<div class="logo-image">
			<img style="width:150px; " src="../../assets/images/pif-logo-clean.png" >
		</div>

		<f7-login-screen-title style="color:#c83c64;">Request New Password</f7-login-screen-title>

		<f7-list form >

			<f7-list-input
				outline
				type="text"
				placeholder="email"
				 v-model:value="user_email"
			></f7-list-input>

			<br>

			<f7-button  style="margin:10px;" @keyup.enter="getNewPassword()"  @click="getNewPassword()" fill round large >Request Password</f7-button>

			<br>

			<f7-button  style="margin:10px;"   @click="goToSignIn()" >Sign In</f7-button>

		</f7-list>


  </f7-page>
</template>

<script>


import { ApiMixin } from '../../mixins/api-mixin.js'
import { f7 } from 'framework7-vue';

export default {

   mixins: [ApiMixin],

    data() {
      return {
        user_email: '',
        user_password: '',
      };
    },

    methods: {

			getNewPassword(){
				var self = this
				f7.request.post(self.api_url+'user/get-new-password', {'email':this.user_email} ).then((response) => {
					response = JSON.parse(response.data);
					f7.dialog.alert('If you entered a valid email address then you will now be sent a new password. Check your email..');
					f7.views.main.router.navigate('/user/login/')
		    });

			},

			goToSignIn(){
				f7.views.main.router.navigate('/user/login/')
			},

		},

};
</script>
<style>

input[type=text]{
    /* -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
     border-radius: 20px !important;
     border: 2px solid #c23464 !important;
     color: #c23464 !important;

     padding-left: 10px !important; */
}

 input[type=text]:focus {
     /* outline: none !important;
     border: 2px solid #c23464 !important;
     color: #c23464 !important;  */
}

.login-screen-page{
	/* background: #CB3867;
	background: -webkit-linear-gradient(top, #CB3867, #F3CFD6);
	background: -moz-linear-gradient(top, #CB3867, #F3CFD6);
	background: linear-gradient(to bottom, #CB3867, #F3CFD6); */
	background-image: url("../../assets/images/swish-background-doodle2.svg");
	background-size: cover;
	background-repeat:no-repeat;
	background-position: center center;


}
.login-screen-title{

}
.list-button{

}
.login-screen-content{
	background:none;
}
.logo-image{
	margin-left:calc(50% - 75px);
	width:150px;
}
</style>
